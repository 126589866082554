/**
 * @packageDocumentation
 * @module utils
 */

/**
 * ```
 * isTruthy :: a -> boolean
 * ```
 *
 * Chcecks if a value is truthy. For numberic values
 * `Infinite` and `NaN` are considered non-truthy.
 *
 * @param value The value to be checked.
 * @typeparam T The type of the value.
 */
export const isTruthy = <T>(value?: T | undefined | null): value is T => {
  if (value) {
    if (typeof value === "string") {
      return !!value.trim();
    } else if (typeof value === "number") {
      return !isNaN(value) && value !== Infinity && (value > 0 || value < 0);
    } else if (Array.isArray(value)) {
      return value.length > 0;
    }

    return !!value;
  }

  return false;
};

/**
 * ```hs
 * isNotNull :: a -> boolean
 * ```
 *
 * Type guard. Checks if a nullable value is null or not.
 *
 * @param value The nullable value to be checked.
 * @typeparam T The type of the value
 * @returns Asserts that the value is not of type `null`
 */
export const isNotNull = <T>(value?: T | null | undefined): value is T =>
  value != null;

/**
 * Regular expression for testing e-mail addresses
 */
// export const emailPattern = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
export const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const phonePattern = /^(\+\d{1,2}\s?)?1?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const decimalNumberPattern = /^\d+(\.\d{1,2})?$/;

export const postalCodeCanada = /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} ?\d{1}[A-Za-z]{1}\d{1}$/;

export const zipCodeUSA = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;

/**
 * ```hs
 * isEmail :: String -> Bool
 * ```
 *
 * Tests a string agains [[emailPattern]] to see if it is a valid e-mail address.
 *
 * @param email
 */
export const isEmail = (email?: string) =>
  isTruthy(email) && emailPattern.test(email);

export const isCanadianPostalCode = (postalCode?: string) =>
  isTruthy(postalCode) && postalCodeCanada.test(postalCode);

export const isUSAZipCode = (zipCode?: string) =>
  isTruthy(zipCode) && zipCodeUSA.test(zipCode);

export const isPhoneNumber = (phoneNumber?: string) =>
  isTruthy(phoneNumber) && phonePattern.test(phoneNumber);

export const isPhone = isPhoneNumber;

export const isDecimalNumber = (numberEntered?: string) =>
  isTruthy(numberEntered) && decimalNumberPattern.test(numberEntered);

export const isFileList = (file: FileList | any): file is FileList =>
  file instanceof FileList;

export const isFile = (file: File | any): file is File => file instanceof File;

export const orElse = <A>(a: A) => <B>(
  b: B | null | undefined
): NonNullable<B> | A => (b == null ? a : (b as NonNullable<B>));

export const fileSizeExceeds = (bytes: number) => (file: File) =>
  file.size > bytes;
