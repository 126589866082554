import * as React from "react";
import { BehaviorSubject } from "rxjs";

import { Failure, isApiFailure, isErrorFailure } from "../../modules/apis/q";

export type Alert = {
  status: "error" | "warning" | "success";
  node: React.ReactNode;
};

export const alerts$ = new BehaviorSubject<Alert[]>([]);

export const alert = (alert: Alert) => {
  alerts$.next([...alerts$.value, alert]);
  return alert;
};

export const clear = (alert?: Alert) =>
  alerts$.next(
    alert ? alerts$.value.filter((current) => current !== alert) : []
  );

export const error = (node: React.ReactNode) =>
  alert({ status: "error", node });

export const warning = (node: React.ReactNode) =>
  alert({ status: "warning", node });

export const success = (node: React.ReactNode) =>
  alert({ status: "success", node });

export const fromFailure = (failure: Failure) => {
  if (isApiFailure(failure)) {
    return failure.error.errors.map((_) => error(_.message));
  } else if (isErrorFailure(failure)) {
    return [error("An unknown error has occured.")];
  }

  return [error("The server responded with an unknown messsage.")];
};
