import classes from "./bms.module.scss";

import * as React from "react";

export const Body = (props: {
  center?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  size?: 1 | 2;
  grid?: boolean;
  padded?: boolean;
  "data-testid"?: string;
}) => (
  <div
    className={`${classes.contentBody} ${props.center ? classes.center : ""}`}
    data-size={props.size || ""}
    data-grid={!!props.grid}
    data-padded={!!props.padded}
    data-testid={props["data-testid"] || ""}
  >
    <div style={props.style}>{props.children}</div>
  </div>
);

export default Body;
